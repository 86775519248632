/*!

=========================================================
* BLK Design System React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";
import CustomModal from "components/CustomModal/CustomModal";

export default function Footer() {
  const [formModal, setFormModal] = React.useState(false);

  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md="2"></Col>
          <Col md="2"></Col>
          <Col md="2">
            <Nav>
              <NavItem>
                <NavLink to="/" tag={Link}>
                  <b>CHUB</b> &copy; {new Date().getFullYear()}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="#" tag={Link} onClick={() => setFormModal(true)}>
                  Privacy Policy
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          {/* <Col md="2">
            <h4 className="title">Customers</h4>
          </Col> */}

          <Col sm="1" xs="6" className="mt-4 mt-sm-4">
            <img
              alt="..."
              className="img-fluid rounded shadow"
              src={require("assets/img/BMW.png")}
              style={{ width: "100px" }}
            />
          </Col>
          <Col sm="1" xs="6" className="mt-4 mt-sm-4">
            <img
              alt="..."
              className="img-fluid rounded shadow"
              src={require("assets/img/Emblem_of_Angola.png")}
              style={{ width: "100px" }}
            />
          </Col>
          {/* <Col sm="1" xs="6" className="mt-4 mt-sm-4">
            <img
              alt="..."
              className="img-fluid rounded shadow"
              src={require("assets/img/Mercedes.png")}
              style={{ width: "100px" }}
            />
          </Col> */}
          {/* <Col md="3"></Col> */}
          {/* <Col sm="8" xs="6">
            <section className="section section-lg section-safe">
              <img
                alt="..."
                className="path"
                src={require("assets/img/path5.png")}
              />
              <Container>
                <Row className="row-grid justify-content-between">
                  <Col md="5">
                    <img
                      alt="..."
                      className="img-fluid floating"
                      src={require("assets/img/chester-wade.jpg")}
                    />
                    <Card className="card-stats bg-danger">
                      <CardBody>
                        <div className="justify-content-center">
                          <div className="numbers">
                            <CardTitle tag="p">100%</CardTitle>
                            <p className="card-category text-white">Safe</p>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <Card className="card-stats bg-info">
                      <CardBody>
                        <div className="justify-content-center">
                          <div className="numbers">
                            <CardTitle tag="p">573 K</CardTitle>
                            <p className="card-category text-white">
                              Satisfied customers
                            </p>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <Card className="card-stats bg-default">
                      <CardBody>
                        <div className="justify-content-center">
                          <div className="numbers">
                            <CardTitle tag="p">10 425</CardTitle>
                            <p className="card-category text-white">Business</p>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </section>
          </Col> */}
        </Row>
        <CustomModal
          title="Política de Privacidade"
          formModal={formModal}
          setFormModal={setFormModal}
        >
          <p>
            At CHUB ZA we collect and manage user data according to the
            following Privacy Policy. Below you will find information concerning
            visits to and the use of CHUB ZA website. CHUB ZA is sensitive to
            privacy issues on the internet and recognizes the importance of
            safeguarding all information we receive from you. This Privacy
            Policy applies to data provided by you and collected by CHUB ZA
            website. We will use the provided information to process requests,
            enquiries and provide a more personalized browsing experience. CHUB
            ZA ensure that all personal information is treated in accordance
            with the applicable data privacy legislation of South Africa.
          </p>
        </CustomModal>
      </Container>
    </footer>
  );
}
