/*!

=========================================================
* BLK Design System React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col, UncontrolledTooltip } from "reactstrap";

export default function Services() {
  return (
    <div className="section section-basic" id="services">
      <img alt="..." className="path" src={require("assets/img/path1.png")} />
      <Container>
        <div className="space-50" />
        <div id="images">
          <div className="title">
            <h3 className="mb-3">Services</h3>
          </div>
          <Row className="mt-5 mt-sm-5 text-center">
            <Col sm="3" xs="6">
              <small className="d-block text-uppercase font-weight-bold mb-4">
                Web Development
              </small>
              <img
                id="web-development"
                alt="..."
                className="img-fluid rounded shadow pointer"
                src={require("assets/img/icons8-web-development-100.png")}
                style={{ width: "100px" }}
              />
              <UncontrolledTooltip placement="bottom" target="web-development">
                Web development is the process of building, programming, and
                maintaining websites and web applications. At CHUB we work with
                various programming languages, such as HTML, CSS, and
                JavaScript/Typescript, React to develop websites and web
                applications.
              </UncontrolledTooltip>
            </Col>
            <Col sm="3" xs="6">
              <small className="d-block text-uppercase font-weight-bold mb-4">
                Cloud and Monitoring Services
              </small>
              <img
                id="cloud"
                alt="..."
                className="img-fluid rounded-circle shadow pointer"
                src={require("assets/img/icons8-clouds-100.png")}
                style={{ width: "100px" }}
              />
              <UncontrolledTooltip placement="bottom" target="cloud">
                The cloud is a global network of remote servers that store data,
                run applications, and deliver content and services over the
                internet. It's a virtual space that allows users to access files
                and data from any device with an internet connection. At CHUB we
                work the following cloud providers AWS and Digital Ocean.
              </UncontrolledTooltip>
            </Col>
            <Col className="mt-5 mt-sm-0" sm="3" xs="6">
              <small className="d-block text-uppercase font-weight-bold mb-4">
                System Integration
              </small>
              <img
                id="system-integration"
                alt="..."
                className="img-fluid rounded shadow-lg"
                src={require("assets/img/icons8-module-100.png")}
                style={{ width: "100px" }}
              />
              <UncontrolledTooltip
                placement="bottom"
                target="system-integration"
              >
                System integration is the process of combining different systems
                and applications into a single, cohesive system that functions
                as one. The goal is to make these systems work together smoothly
                so they can share information and processes more efficiently. At
                CHUB we provide the following system integrations API's,
                Payments, SMS, USSD, System to System.
              </UncontrolledTooltip>
            </Col>
            <Col className="mt-5 mt-sm-0" sm="3" xs="6">
              <small className="d-block text-uppercase font-weight-bold mb-4">
                DevOps
              </small>
              <img
                id="devops"
                alt="..."
                className="img-fluid rounded-circle shadow-lg"
                src={require("assets/img/icons8-devops-100.png")}
                style={{ width: "100px" }}
              />
              <UncontrolledTooltip placement="bottom" target="devops">
                DevOps is a software development methodology that combines
                practices, tools, and a cultural philosophy to improve and speed
                up the software development life cycle. The term DevOps is a
                combination of the words "development" and "operations". We
                successfully adopted the DevOps methodology, by shifting the way
                we work and collaborate. From culture change to automation with
                continuous integration and continuous deployment (CI/CD).
              </UncontrolledTooltip>
            </Col>
          </Row>
          <Row className="mt-5 mt-sm-5 text-center">
            <Col sm="3" xs="6">
              <small className="d-block text-uppercase font-weight-bold mb-4">
                Design UX/UI
              </small>
              <img
                id="design-ux-ui"
                alt="..."
                className="img-fluid rounded shadow"
                src={require("assets/img/icons8-design-100.png")}
                style={{ width: "100px" }}
              />
              <UncontrolledTooltip placement="bottom" target="design-ux-ui">
                UX/UI design is the process of creating a product or service
                that provides a good user experience by designing the user
                interface (UI) and user experience (UX), we use Figma to
                collaboratively design, building meaningful products with
                seamlessly design, prototype, develop.
              </UncontrolledTooltip>
            </Col>
            <Col sm="3" xs="6">
              <small className="d-block text-uppercase font-weight-bold mb-4">
                SEO
              </small>
              <img
                id="seo"
                alt="..."
                className="img-fluid rounded-circle shadow"
                src={require("assets/img/icons8-seo-100.png")}
                style={{ width: "100px" }}
              />
              <UncontrolledTooltip placement="bottom" target="seo">
                SEO (search engine optimization) — is about helping search
                engines understand your content, and helping users find your
                site and make a decision about whether they should visit your
                site through a search engine. We can aid our customers reach
                google as well as major search engines #1 rank.
              </UncontrolledTooltip>
            </Col>
            <Col className="mt-5 mt-sm-0" sm="3" xs="6">
              <small className="d-block text-uppercase font-weight-bold mb-4">
                Marketing
              </small>
              <img
                id="marketing"
                alt="..."
                className="img-fluid rounded shadow-lg"
                src={require("assets/img/icons8-web-advertising-100.png")}
                style={{ width: "100px" }}
              />
              <UncontrolledTooltip placement="bottom" target="marketing">
                Online marketing, also known as digital marketing, is the use of
                the internet and other digital technologies to promote a
                company's products, services, or brand to potential customers.
                We also provide Professional Management and Implementation of
                all Google Adwords Features as well as Social Media our
                specialist team will take care of your brand on all major social
                media platforms, Search engine marketing (SEM), Web Analytics,
                Customer relationship management (CRM) as well as Email
                campaigns.
              </UncontrolledTooltip>
            </Col>
            <Col className="mt-5 mt-sm-0" sm="3" xs="6">
              <small className="d-block text-uppercase font-weight-bold mb-4">
                Project Management
              </small>
              <img
                id="project-management"
                alt="..."
                className="img-fluid rounded-circle shadow-lg"
                src={require("assets/img/icons8-project-management-100.png")}
                style={{ width: "100px" }}
              />
              <UncontrolledTooltip
                placement="bottom"
                target="project-management"
              >
                Project management is the process of planning, organizing, and
                executing a project to achieve its goals within the constraints
                of time, budget, and scope. It involves coordinating people and
                resources to meet deadlines and deliver a product, service, or
                deliverable. We can help your business with E2E project
                management from defining scopes, identifying deliverables and
                managing risk.
              </UncontrolledTooltip>
            </Col>
          </Row>
          <Row className="mt-5 mt-sm-5 text-center">
            <Col className="mt-5 mt-sm-0" sm="3" xs="6">
              <small className="d-block text-uppercase font-weight-bold mb-4">
                Mobile Development
              </small>
              <img
                id="mobile"
                alt="..."
                className="img-fluid rounded-circle shadow-lg"
                src={require("assets/img/icons8-android-phone-100.png")}
                style={{ width: "100px" }}
              />
              <UncontrolledTooltip placement="bottom" target="mobile">
                Mobile development is the process of creating software
                applications that run on mobile devices, such as smartphones,
                tablets, and digital assistants. The process involves several
                stages, including: ideation, design, coding, testing, and
                deployment. Art CHUB we work with React Native to develop mobile
                applications.
              </UncontrolledTooltip>
            </Col>
            <Col className="mt-5 mt-sm-0" sm="3" xs="6">
              <small className="d-block text-uppercase font-weight-bold mb-4">
                Software Development
              </small>
              <img
                id="software-development"
                alt="..."
                className="img-fluid rounded-circle shadow-lg"
                src={require("assets/img/icons8-software-development-100.png")}
                style={{ width: "100px" }}
              />
              <UncontrolledTooltip
                placement="bottom"
                target="software-development"
              >
                Software development is the process of creating, designing,
                testing, and maintaining software applications. It involves a
                variety of activities, including: Project conception,
                Requirements analysis, Software design, Coding, Testing,
                Deployment.
              </UncontrolledTooltip>
            </Col>
            <Col className="mt-5 mt-sm-0" sm="3" xs="6">
              <small className="d-block text-uppercase font-weight-bold mb-4">
                Cyber Security
              </small>
              <img
                alt="..."
                className="img-fluid rounded-circle shadow-lg"
                src={require("assets/img/icons8-cyber-security-100.png")}
                style={{ width: "100px" }}
              />
            </Col>

            <Col className="mt-5 mt-sm-0" sm="3" xs="6">
              <small className="d-block text-uppercase font-weight-bold mb-4">
                Q&A - Software Testing
              </small>
              <img
                id="q-a"
                alt="..."
                className="img-fluid rounded-circle shadow-lg"
                src={require("assets/img/icons8-test-100.png")}
                style={{ width: "100px" }}
              />
              <UncontrolledTooltip placement="bottom" target="q-a">
                QA testing is a process that evaluates the functionality,
                security, performance, and usability of a software product. At
                CHUB ensure that software meets user needs and expectations. We
                test software to check that software works as intended, while we
                QA to ensure the software development process produces
                high-quality software. Some of the tools we work with are
                Playwright, JUnit and K6.
              </UncontrolledTooltip>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
