/*!

=========================================================
* BLK Design System React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

export default function TeckStack() {
  return (
    <div id="tech-stack" className="section section-nucleo-icons">
      <img alt="..." className="path" src={require("assets/img/path3.png")} />
      <Container>
        <Row className="justify-content-center">
          <Col lg="8" md="12">
            <h2 className="title">Tech Stack</h2>
            {/* <h4 className="description">
              BLK• Design System PRO comes with 100 custom icons made by our
              friends from NucleoApp. The official package contains over 2.100
              thin icons which are looking great in combination with BLK• Design
              System PRO Make sure you check all of them and use those that you
              like the most.
            </h4> */}
          </Col>
        </Row>

        <div>
          <div className="icons-container blur-item on-screen mt-5">
            {/* Center */}
            {/* <i className="icon tim-icons icon-coins" /> */}
            <i className="icon">
              <img
                alt="..."
                className="img-fluid rounded shadow"
                src={require("assets/img/icons8-html5-100.png")}
                style={{ width: "50px" }}
              />
            </i>
            {/* Right 1 */}
            {/* <i className="icon icon-sm tim-icons icon-spaceship" /> */}
            <i className="icon icon-sm">
              <img
                alt="..."
                className="img-fluid rounded shadow"
                src={require("assets/img/icons8-css3-100.png")}
                style={{ width: "50px" }}
              />
            </i>
            {/* <i className="icon icon-sm tim-icons icon-money-coins" /> */}
            <i className="icon icon-sm">
              <img
                alt="..."
                className="img-fluid rounded shadow"
                src={require("assets/img/icons8-mongo-100.png")}
                style={{ width: "50px" }}
              />
            </i>
            {/* <i className="icon icon-sm tim-icons icon-link-72" /> */}
            <i className="icon icon-sm">
              <img
                alt="..."
                className="img-fluid rounded shadow"
                src={require("assets/img/icons8-react-native-100.png")}
                style={{ width: "50px" }}
              />
            </i>
            {/* Right 2 */}
            {/* <i className="icon tim-icons icon-send" /> */}
            <i className="icon">
              <img
                alt="..."
                className="img-fluid rounded shadow"
                src={require("assets/img/icons8-hashicorp-100.png")}
                style={{ width: "50px" }}
              />
            </i>
            {/* <i className="icon tim-icons icon-mobile" /> */}
            <i className="icon">
              <img
                alt="..."
                className="img-fluid rounded shadow"
                src={require("assets/img/icons8-postgresql-100.png")}
                style={{ width: "50px" }}
              />
            </i>
            {/* <i className="icon tim-icons icon-wifi" /> */}
            <i className="icon">
              <img
                alt="..."
                className="img-fluid rounded shadow"
                src={require("assets/img/icons8-aws-100.png")}
                style={{ width: "50px" }}
              />
            </i>
            {/* <i className="icon icon-sm tim-icons icon-key-25" /> */}
            <i className="icon icon-sm">
              <img
                alt="..."
                className="img-fluid rounded shadow"
                src={require("assets/img/icons8-javascript-100.png")}
                style={{ width: "50px" }}
              />
            </i>

            {/* <i className="icon icon-sm tim-icons icon-atom" /> */}
            <i className="icon icon-sm">
              <img
                alt="..."
                className="img-fluid rounded shadow"
                src={require("assets/img/icons8-docker-100.png")}
                style={{ width: "50px" }}
              />
            </i>
            {/* <i className="icon icon-sm tim-icons icon-satisfied" /> */}
            <i className="icon icon-sm">
              <img
                alt="..."
                className="img-fluid rounded shadow"
                src={require("assets/img/icons8-digital-ocean-100.png")}
                style={{ width: "50px" }}
              />
            </i>
            {/* Left 2 */}
            {/* <i className="icon tim-icons icon-gift-2" /> */}
            <i className="icon">
              <img
                alt="..."
                className="img-fluid rounded shadow"
                src={require("assets/img/icons8-figma-100.png")}
                style={{ width: "50px" }}
              />
            </i>
            {/* <i className="icon tim-icons icon-tap-02" /> */}
            <i className="icon">
              <img
                alt="..."
                className="img-fluid rounded shadow"
                src={require("assets/img/icons8-core-100.png")}
                style={{ width: "50px" }}
              />
            </i>
            {/* <i className="icon tim-icons icon-wallet-43" /> */}
            <i className="icon">
              <img
                alt="..."
                className="img-fluid rounded shadow"
                src={require("assets/img/icons8-nodejs-100.png")}
                style={{ width: "50px" }}
              />
            </i>
          </div>
        </div>
      </Container>
    </div>
  );
}
