/*!

=========================================================
* BLK Design System React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";
// reactstrap components
import {
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

export default function AboutUs() {
  const [iconTabs, setIconsTabs] = React.useState(1);
  const [textTabs, setTextTabs] = React.useState(4);
  return (
    <div id="about-us" className="section section-tabs">
      <Container>
        <div className="title">
          <h3 className="mb-3">About Us</h3>
        </div>
        <Row>
          <Col className="ml-auto mr-auto" md="10" xl="6">
            <Card>
              <CardHeader>
                <Nav className="nav-tabs-info" role="tablist" tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: iconTabs === 1,
                      })}
                      onClick={(e) => setIconsTabs(1)}
                      href="#"
                    >
                      <i className="tim-icons icon-single-copy-04" />
                      Summary
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: iconTabs === 2,
                      })}
                      onClick={(e) => setIconsTabs(2)}
                      href="#"
                    >
                      <i className="tim-icons icon-spaceship " />
                      Our Mission
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardHeader>
              <CardBody>
                <TabContent className="tab-space" activeTab={"link" + iconTabs}>
                  <TabPane tabId="link1">
                    <p>
                      CHUB (Pty) Ltd is a private company operating within the
                      dynamic landscape of the IT sector. We specialise in
                      providing comprehensive IT consultancy services with a
                      commitment to delivering exceptional value and expertise.
                      <br />
                      <br />
                      CHUB presents itself as a reliable ally to organisations
                      or individuals aiming to boost their technological
                      capabilities and streamline operations, as well as for
                      software engineers who seek a trustworthy partner focused
                      on their career and financial goals, while also nurturing
                      their growth in their chosen field of expertise.
                      <br />
                      <br />
                      This dedication to excellence ensures that the firm
                      remains at the forefront of industry developments,
                      catering to the diverse needs of our clientele. In a
                      rapidly evolving digital environment, the services offered
                      by CHUB are indispensable to those aiming to navigate the
                      complexities of information technology effectively.
                    </p>
                  </TabPane>
                  <TabPane tabId="link2">
                    <p>
                      CHUB was established with the purpose of addressing a
                      significant and widespread issue in both the IT industry
                      and the corporate sphere. The problem at hand concerns the
                      absence of harmony between skilled and enthusiastic
                      engineers, who frequently encounter undervaluation and
                      excessive demands, without being provided with adequate
                      support, recognition, or fair compensation, and clients
                      who possess impractical expectations coupled with a
                      limited comprehension or regard for the expertise of the
                      advising engineers.
                    </p>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
          <Col className="ml-auto mr-auto" md="10" xl="6">
            <Card>
              <CardHeader>
                <Nav className="nav-tabs-info" role="tablist" tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: textTabs === 4,
                      })}
                      onClick={(e) => setTextTabs(4)}
                      href="#"
                    >
                      <i className="tim-icons icon-single-02" />
                      The team
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: textTabs === 5,
                      })}
                      onClick={(e) => setTextTabs(5)}
                      href="#"
                    >
                      <i className="tim-icons icon-square-pin" />
                      Location
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardHeader>
              <CardBody>
                <TabContent className="tab-space" activeTab={"link" + textTabs}>
                  <TabPane tabId="link4">
                    <Row className="mt-5 mt-sm-5 text-center">
                      <Col sm="3" xs="6">
                        <img
                          alt="..."
                          className="img-fluid rounded shadow"
                          src={require("assets/img/icons8-user-100.png")}
                          style={{ width: "100px" }}
                        />
                        <small className="d-block text-uppercase font-weight-bold mt-4">
                          Wilson Cardoso
                        </small>
                      </Col>
                      <Col sm="3" xs="6">
                        <img
                          alt="..."
                          className="img-fluid rounded shadow"
                          src={require("assets/img/icons8-user-100.png")}
                          style={{ width: "100px" }}
                        />
                        <small className="d-block text-uppercase font-weight-bold mt-4">
                          Lauren Cardoso
                        </small>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="link5">
                    <p>Our team work remotely from the following locations</p>
                    <Row className="mt-5 mt-sm-5 text-center">
                      <Col sm="3" xs="6">
                        <img
                          alt="..."
                          className="img-fluid rounded shadow"
                          src={require("assets/img/icons8-location-100.png")}
                          style={{ width: "50px" }}
                        />
                        <small className="d-block text-uppercase font-weight-bold mt-2">
                          South Africa
                        </small>
                      </Col>
                      <Col sm="3" xs="6">
                        <img
                          alt="..."
                          className="img-fluid rounded shadow"
                          src={require("assets/img/icons8-location-100.png")}
                          style={{ width: "50px" }}
                        />
                        <small className="d-block text-uppercase font-weight-bold mt-2">
                          Angola
                        </small>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
